import React from "react";

import { Link } from "react-router-dom";

const EnrollOption = () => {
  return (
    <>
      <div className="enrollOpt__container">
        <h2 className="enrollOpt__heading">Enroll Here</h2>
        <div className="enrollOpt__btn--container">
          <div className="enrollOpt">
            <h4 className="enrollOpt__sub-heading">Indivdual/ Group Entry</h4>
            <Link to="/entryForm">
              <button className="enrollOpt__btn">Enroll Now</button>
            </Link>
          </div>
          <div className="enrollOpt">
            <h4 className="enrollOpt__sub-heading">Special Categories</h4>
            <Link to="/specialcat">
              <button className="enrollOpt__btn">Enroll Now</button>
            </Link>
          </div>
        </div>

        <div className="enrollOpt__note--wrapper">
          <div className="note__container">
            <h4 className="note__heading">Dates Of Eisteddfod:</h4>
            <p className="note__para">
              15-19 July 2024 (in Person at School of Achievement) Online
              closing date – 26 July 2024
            </p>
          </div>
        </div>
        <div className="enrollOpt__note--wrapper">
          <div className="note__container">
            <h4 className="note__heading">Please Note:</h4>
            <p className="note__para">
              Any group /individual item below 10 learners - R70 per item per learner <br />
              <br />
              Any group item above 10 learners, includes Bands, Choirs, Dance Groups, Drama Plays – R350 per group <br />
              These group entries MUST be submitted to the <b>Special Categories</b> section.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnrollOption;
