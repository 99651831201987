import React, { useState } from "react";
import { PaystackButton } from "react-paystack";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";
import CATEGORY_DATA from "./categoryData/categoryData";
import Modal from "./popUp/Modal";
import {
  EMAIL_SERVICE_ID,
  USER_ID,
  EMAIL_TEMPLATE_ENTRY_FORM,
  EMAIL_TEMPLATE_RECEIPT,
} from "../../assets/email";

const EntryForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    age: "",
    school: "",
    phoneNum: "",
    location: "Select Location",
    entry: "",
    language: "",
    numNames: 0,
    numItemsEntered: 0,
    amount: 0,
  });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [displayedItems, setDisplayedItems] = useState([]);
  const [displayedNames, setDisplayedNames] = useState([]);
  const [isGroupChecked, setIsGroupChecked] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const emailServiceID = EMAIL_SERVICE_ID;
  const emailTemplateEntryForm = EMAIL_TEMPLATE_ENTRY_FORM;
  const emailTemplateReceipt = EMAIL_TEMPLATE_RECEIPT;
  const userID = USER_ID;

  const handleGroupCheckboxChange = (event) => {
    setIsGroupChecked(event.target.checked);
  };

  const handleNameChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      name: event.target.value,
    }));
  };

  const handleAddName = () => {
    if (formData.name) {
      setDisplayedNames([...displayedNames, formData.name]);

      // Update the state
      setFormData((prevData) => ({
        ...prevData,
        name: "",
        numNames: prevData.numNames + 1,
      }));
    }
  };

  const handleRemoveName = (index) => {
    const updatedNames = [...displayedNames];
    updatedNames.splice(index, 1);
    setDisplayedNames(updatedNames);

    setFormData((prevData) => ({
      ...prevData,
      numNames: prevData.numNames - 1, // Decrement the number of names
    }));
  };

  const handleEmailChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      email: event.target.value,
    }));
  };

  const handleSchoolChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      school: event.target.value,
    }));
  };

  const handleAgeChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      age: event.target.value,
    }));
  };

  const handlePhoneNumChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      phoneNum: event.target.value,
    }));
  };

  const handleLocationChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      location: event.target.value,
    }));
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedItem("");
  };

  const handleItemChange = (event) => {
    setSelectedItem(event.target.value);
  };

  const handleAddItem = () => {
    if (selectedCategory && selectedItem) {
      const alreadyAdded = displayedItems.some(
        (item) =>
          item.category === selectedCategory && item.item === selectedItem
      );

      if (alreadyAdded) {
        alert(`${selectedItem} has already been added.`);
      } else {
        const newItem = { category: selectedCategory, item: selectedItem };
        const newDisplayedItems = [...displayedItems, newItem];

        setDisplayedItems(newDisplayedItems);

        const newItemCount = newDisplayedItems.length;
        const newAmount = newItemCount * 7000;

        setFormData((prevData) => ({
          ...prevData,
          numItemsEntered: newItemCount,
          amount: newAmount,
        }));

        // Update the entry string
        generateEntryString(newDisplayedItems);
      }
    }
  };

  const handleRemoveItemFromGroup = (category) => {
    const updatedGroupedItems = { ...groupedItems };
    const updatedItems = [...updatedGroupedItems[category]];
    updatedItems.pop();
    updatedGroupedItems[category] = updatedItems;

    // Update the displayed items with the modified grouped items
    const updatedDisplayedItems = Object.entries(updatedGroupedItems)
      .map(([category, items]) => items.map((item) => ({ category, item })))
      .flat();

    setDisplayedItems(updatedDisplayedItems);

    setFormData((prevData) => ({
      ...prevData,
      numItemsEntered: prevData.numItemsEntered - 1, // Decrement the number of items entered
    }));
  };

  const groupedItems = displayedItems.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item.item);
    return acc;
  }, {});

  const generateEntryString = (displayedItems) => {
    const entryArray = displayedItems.map(({ category, item }) => {
      return `${category}: ${item}`;
    });

    const entryString = entryArray.join("\n");

    // Update the formData.entry state
    setFormData((prevData) => ({
      ...prevData,
      entry: entryString,
    }));

    return entryString;
  };

  const handleLanguageChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      language: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation checks
    const errors = {};

    if (formData.name.length < 3) {
      errors.name = "Name must be longer than 3 characters.";
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (formData.school.length < 3) {
      errors.school = "School name must be longer than 3 characters.";
    }

    if (Object.keys(errors).length > 0) {
      alert("Validation errors:\n" + Object.values(errors).join("\n"));
    } else {
      const entry = generateEntryString(displayedItems); // Generate the entry string
      setFormData((prevData) => ({
        ...prevData,
        entry: entry, // Set the entry string in formData
      }));
    }
  };

  const handleSuccess = ({ reference }) => {
    setModalContent(
      `Your purchase was successful! Transaction reference: ${reference}`
    );
    setShowModal(true);
    resetForm();
    sendEmailReceipt(formData.email, formData.name, reference);
    sendEmailEntryForm(formData.email, formData.name, reference);
  };

  const handleClose = () => {
    setModalContent("Wait! Are you sure you don't want to enroll?");
    setShowModal(true);
  };

  const form_groupChecked = `${
    isGroupChecked ? `GROUP ENTRY` : `${formData.name}`
  }`;
  const form_email = `${formData.email}`;
  const form_age = `${formData.age}`;
  const form_school = `${formData.school}`;
  const form_phoneNum = `${formData.phoneNum}`;
  const form_location = `${formData.location}`;
  const form_language = `${formData.language}`;
  const form_items = `${formData.entry}`;
  const form_names = `${
    isGroupChecked ? `${displayedNames.join(", ")},` : `${formData.name}`
  }`;
  const form_totalNames = `${
    isGroupChecked ? `${formData.numNames},` : "Individual Entry"
  }`;
  const form_totalItems = `${formData.numItemsEntered}`;
  const form_amount = `${
    isGroupChecked
      ? (formData.amount * formData.numNames) / 100
      : formData.amount / 100
  }`;

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      age: "",
      school: "",
      phoneNum: "",
      location: "Select Location",
      entry: "",
      language: "",
      numNames: 0,
      numItemsEntered: 0,
      amount: 0,
    });
    setIsGroupChecked(false);
    setDisplayedNames([]);
    setDisplayedItems([]);
  };

  const sendEmailEntryForm = async (toEmail, toName, reference) => {
    const templateParamsForForm = {
      to_email: toEmail,
      to_name: toName,
      from_name: isGroupChecked ? "Group" : formData.name,
      from_email: formData.email,
      form_groupChecked: form_groupChecked,
      form_email: form_email,
      form_age: form_age,
      form_school: form_school,
      form_phoneNum: form_phoneNum,
      form_location: form_location,
      form_language: form_language,
      form_items: form_items,
      form_names: form_names,
      form_totalNames: form_totalNames,
      form_totalItems: form_totalItems,
      form_amount: form_amount,
      reference: reference,
    };

    await emailjs.send(
      emailServiceID,
      emailTemplateEntryForm,
      templateParamsForForm,
      userID
    );
  };
  const sendEmailReceipt = async (toEmail, toName, reference) => {
    const templateParamsForReceipt = {
      to_email: toEmail,
      to_name: toName,
      from_name: isGroupChecked ? "Group" : formData.name,
      from_email: formData.email,
      form_groupChecked: form_groupChecked,
      form_email: form_email,
      form_age: form_age,
      form_school: form_school,
      form_phoneNum: form_phoneNum,
      form_location: form_location,
      form_language: form_language,
      form_items: form_items,
      form_names: form_names,
      form_totalNames: form_totalNames,
      form_totalItems: form_totalItems,
      form_amount: form_amount,
      reference: reference,
    };

    await emailjs.send(
      emailServiceID,
      emailTemplateReceipt,
      templateParamsForReceipt,
      userID
    );
  };

  const email = formData.email;
  const name = formData.name;
  const amount = isGroupChecked
    ? formData.amount * formData.numNames
    : formData.amount;
  const phone = formData.phoneNum;
  const currency = "ZAR";
  const publicKey = "pk_live_7f669c49c720f76bc90db22e3b46e31a131ff7a3";

  const componentProps = {
    email,
    amount,
    currency,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Submit",
    onSuccess: ({ reference }) => {
      handleSuccess({ reference });
      resetForm();
    },
    onClose: handleClose,
  };

  return (
    <>
      <form className="enrollment__form" onSubmit={handleSubmit}>
        <div className="form__enrollment--wrapper">
          <h3 className="form__enrollment--title">Enrollment Form</h3>
          <div className="enrollment__input--wrapper">
            <input
              type="text"
              name="name"
              value={formData.name}
              placeholder="Full Name of participant"
              onChange={handleNameChange}
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Email"
              onChange={handleEmailChange}
              required
            />
            <input
              type="text"
              name="school"
              placeholder="School"
              value={formData.school}
              onChange={handleSchoolChange}
              required
            />
            <select
              name="age"
              value={formData.age}
              onChange={handleAgeChange}
              required
            >
              <option value="" disabled>
                Select Age
              </option>
              {[...Array(17)].map((_, index) => (
                <option key={index} value={index + 5}>
                  {index + 5} years of age
                </option>
              ))}
            </select>
            <input
              type="text"
              name="phoneNum"
              placeholder="Phone"
              value={formData.phoneNum}
              onChange={handlePhoneNumChange}
              required
            />
            <select
              name="location"
              value={formData.location}
              onChange={handleLocationChange}
              required
            >
              <option value="Select Location" disabled>
                Select Venue
              </option>
              <option value="In Person">In Person</option>
              <option value="Online">Online</option>
            </select>
            <div class="checkbox-wrapper-32">
              <input
                type="checkbox"
                name="checkbox-32"
                id="checkbox-32"
                checked={isGroupChecked}
                onChange={handleGroupCheckboxChange}
              />
              <label for="checkbox-32">Group Entry?</label>
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M 10 10 L 90 90"
                  stroke="#000"
                  stroke-dasharray="113"
                  stroke-dashoffset="113"
                ></path>
                <path
                  d="M 90 10 L 10 90"
                  stroke="#000"
                  stroke-dasharray="113"
                  stroke-dashoffset="113"
                ></path>
              </svg>
            </div>
          </div>
        </div>

        <div className="form__entry--wrapper">
          <h3 className="form__entry--title">Entry Form</h3>
          <div className="entry__input--wrapper">
            <div>
              <div className="select__wrapper">
                <label htmlFor="language">Select Language:</label>
                <div>
                  <select
                    id="language"
                    value={formData.language}
                    onChange={handleLanguageChange}
                    required
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="English">English</option>
                    <option value="Afrikaans">Afrikaans</option>
                  </select>
                </div>
              </div>
              <div className="select__wrapper">
                <label htmlFor="category">Select Category:</label>
                <div>
                  <select
                    id="category"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {CATEGORY_DATA.map((categoryObj, index) => {
                      const categoryName = Object.keys(categoryObj)[0];
                      return (
                        <option key={index} value={categoryName}>
                          {categoryName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {selectedCategory && (
                <div className="select__wrapper">
                  <label htmlFor="item">Select Item:</label>
                  <div>
                    <select
                      className="entry__item--select"
                      id="item"
                      value={selectedItem}
                      onChange={handleItemChange}
                    >
                      <option value="">Select</option>
                      {CATEGORY_DATA.find(
                        (categoryObj) => categoryObj[selectedCategory]
                      )[selectedCategory].map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="entry__btn--wrapper">
            {isGroupChecked && (
              <button className="entry__btn" onClick={handleAddName}>
                Add Name
              </button>
            )}
            <button className="entry__btn" onClick={handleAddItem}>
              Add Item
            </button>
          </div>
          <div className="displayed__nameswithitems">
            {isGroupChecked && (
              <div className="displayed__names">
                <h3>Names Added:</h3>
                <ul className="displayed-names-list">
                  {displayedNames.map((name, index) => (
                    <li className="displayed-name" key={index}>
                      {name}
                      <button
                        onClick={() => handleRemoveName(index)}
                        className="remove-button"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="displayed__items">
              <h3>Chosen Items:</h3>
              <ul className="displayed-items-list">
                {Object.entries(groupedItems).map(([category, items], index) =>
                  items.map((item, itemIndex) => (
                    <li key={`${index}-${itemIndex}`}>
                      <span className="category-text">{category}:</span> {item}
                      <button
                        onClick={() => handleRemoveItemFromGroup(category)}
                        className="remove-button"
                      >
                        Remove
                      </button>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>

          <div>
            <PaystackButton className="paystack-button" {...componentProps} />
          </div>
          <Modal show={showModal} onClose={() => setShowModal(false)}>
            <p>{modalContent}</p>
          </Modal>
          <div className="exit__wrapper">
            <Link to="/" className="exit__btn">
              Home
            </Link>
          </div>
        </div>
      </form>
    </>
  );
};

export default EntryForm;
