import React from "react";
import { Link } from "react-router-dom";
import { FaPhone, FaEnvelope } from "react-icons/fa";

import logo from '../../../assets/LOGO_PE-removebg-preview.png';

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-content-row">
        <figure className="footer-logo">
          <img src={logo} className="footer-logo_img" alt="Logo" />
        </figure>
        <div className="links-container">
          <h4>Quick Links</h4>
          <ul className="footer-links">
            <li>
              <Link to="/" className="footer-link">Home</ Link>
            </li>
            <li>
              <Link to="/about" className="footer-link">About</Link>
            </li>
            <li>
              <Link to="/prospectus" className="footer-link">Prospectus</Link>
            </li>
            <li>
              <Link to="/enrollOption" className="footer-link">Enroll</Link>
            </li>
            <li>
              <Link to="/gallery" className="footer-link">Gallery</Link>
            </li>
            <li>
              <Link to="/contact" className="footer-link">Contact</Link>
            </li>
          </ul>
        </div>

        <div className="contact-contianer">
          <h4>Contact Us</h4>
          <div className="contact-info">
            <p><FaEnvelope /> <a href="mailto:admin@perservaria.co.za">admin@perservaria.co.za</a></p>
            <p><FaPhone /><a href="tel:0797974082">0797974082</a></p>
          </div>
        </div>
      </div>

      <hr />

      <div className="footer-copyright-row">
        <div className="copyright">
          <p className="copyright-text">
            &copy; {new Date().getFullYear()} All Rights Reserved by{" "}
            <a href="https://devmarx-portfolio.netlify.app/" target="_blank">
              DevMarx
            </a>
            .
          </p>
          <p className="copyright-text">
            Terms of use | Privacy Environmental Policy
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
