import React from "react";

import StandOutBlock from "./StandOutBlock/StandOutBlock";
import Mission from "./Mission/Mission";
import Vision from "./Vision/Vision";
import Contact from "./Contact/Contact";

const Home = () => {
  return (
    <>
      <StandOutBlock />
      <Mission />
      <Vision />
      <Contact />
    </>
  );
};

export default Home;
