import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../../assets/LOGO_PE-removebg-preview.png";

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const stickyOffset = document.getElementById("nav").offsetTop;

    const handleScroll = () => {
      if (window.scrollY >= stickyOffset) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function openMenu() {
    setShowMenu(true);
    document.body.classList.add("menu--open");
  }

  function closeMenu() {
    setShowMenu(false);
    document.body.classList.remove("menu--open");
  }

  return (
    <>
      <nav id="nav" className={`navbar ${isSticky ? "sticky" : ""}`}>
        <div className="navbar__logo">
          <img src={logo} alt="" className="navbar__logo--img" />
          <h4 className="navbar__logo--text">Perservaria Eisteddfod</h4>
        </div>
        <button className="btn__menu" onClick={openMenu}>
          <FaBars />
        </button>
        <ul className="navbar__links">
          <li className="navbar__link">
            <Link
              to="/"
              className={`navbar__link--primary ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              Home
            </Link>
          </li>
          <li className="navbar__link">
            <Link
              to="/about"
              className={`navbar__link--primary ${
                location.pathname === "/about" ? "active" : ""
              }`}
            >
              About
            </Link>
          </li>
          <li className="navbar__link">
            <Link
              to="/prospectus"
              className={`navbar__link--primary ${
                location.pathname === "/prospectus" ? "active" : ""
              }`}
            >
              Prospectus
            </Link>
          </li>
          <li className="navbar__link">
            <Link
              to="/enrollOption"
              className={`navbar__link--primary ${
                location.pathname === "/enrollOption" ? "active" : ""
              }`}
            >
              Enroll
            </Link>
          </li>
          <li className="navbar__link">
            <Link
              to="/gallery"
              className={`navbar__link--primary ${
                location.pathname === "/gallery" ? "active" : ""
              }`}
            >
              Gallery
            </Link>
          </li>
          <li className="navbar__link">
            <Link
              to="/contact"
              className={`navbar__link--primary ${
                location.pathname === "/contact" ? "active" : ""
              }`}
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
      {showMenu && (
        <div className="menu__backdrop">
          <button className="btn__menu btn__menu--close" onClick={closeMenu}>
            <FaTimes />
          </button>
          <ul className="menu__links">
            <li className="menu__list">
              <Link
                onClick={closeMenu}
                to="/"
                className={`menu__link ${
                  location.pathname === "/" ? "active" : ""
                }`}
              >
                Home
              </Link>
            </li>
            <li className="menu__list">
              <Link
                onClick={closeMenu}
                to="/about"
                className={`menu__link ${
                  location.pathname === "/about" ? "active" : ""
                }`}
              >
                About
              </Link>
            </li>
            <li className="menu__list">
              <Link
                onClick={closeMenu}
                to="/prospectus"
                className={`menu__link ${
                  location.pathname === "/prospectus" ? "active" : ""
                }`}
              >
                Prospectus
              </Link>
            </li>
            <li className="menu__list">
              <Link
                onClick={closeMenu}
                to="/enrollOption"
                className={`menu__link ${
                  location.pathname === "/enrollOption" ? "active" : ""
                }`}
              >
                Enroll
              </Link>
            </li>
            <li className="menu__list">
              <Link
                onClick={closeMenu}
                to="/gallery"
                className={`menu__link ${
                  location.pathname === "/gallery" ? "active" : ""
                }`}
              >
                Gallery
              </Link>
            </li>
            <li className="menu__list">
              <Link
                onClick={closeMenu}
                to="/contact"
                className={`menu__link ${
                  location.pathname === "/contact" ? "active" : ""
                }`}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Navbar;
