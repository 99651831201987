import React from "react";

const Vision = () => {
  return (
    <>
      <div className="vision__container">
        <h2 className="vision__title">Our Vision</h2>
        <div className="visions">
          <div className="vision bg-color-1">
            <img src="" alt="" className="vision__icon" />
            <h3 className="vision__sub-title">Confidence</h3>
            <p className="vision__para">
              In the past, learners with special needs were singled out due to
              their disabilities. They were often marked down for performances
              due to the fact that they performed differently.
            </p>
            <br />
            <p className="vision__para">
              We strive to build the confidence of each learner that
              participates, that they too can shine with their talents.
            </p>
          </div>
          <div className="vision bg-color-2">
            <img src="" alt="" className="vision__icon" />
            <h3 className="vision__sub-title">Creativity</h3>
            <p className="vision__para">
              Creativity is valued and celebrated. There are no restrictions in
              terms of creative expression.
            </p>
            <br />
            <p className="vision__para">
              Although items are adjudicated according to a rubric following
              specific criteria, each individual or group item is adjudicated on
              merit.
            </p>
          </div>
          <div className="vision bg-color-3">
            <img src="" alt="" className="vision__icon" />
            <h3 className="vision__sub-title">Holistic Approach</h3>
            <p className="vision__para">
              The Eisteddfod aims to adjudicate the performance holistically and
              with pathos, taking their challenges into account while inspiring
              growth and self development.
            </p>
          </div>
          <div className="vision bg-color-1">
            <img src="" alt="" className="vision__icon" />
            <h3 className="vision__sub-title">Expanding Network</h3>
            <p className="vision__para">
              As an organisation we want to create a space for all special needs
              schools, centers and learners to feel valued.
            </p>
            <br />
            <p className="vision__para">
              We want them to be able to socialize and communicate with a whole
              network of different personalities that share a love for the arts!
            </p>
          </div>
          <div className="vision bg-color-2">
            <img src="" alt="" className="vision__icon" />
            <h3 className="vision__sub-title">Setting The Benchmark</h3>
            <p className="vision__para">
              By being one of the few Eisteddfods in the country that caters to
              special needs learners, we want to set the standard of what it
              means to be a special needs performer.
            </p>
            <br />
            <p className="vision__para">
              With this ideology we want to provide educational opportunities to
              special needs individuals in terms of training and coaching.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vision;
