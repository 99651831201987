import React from "react";

const Modal = ({ show, onClose, children }) => {
  const modalStyles = {
    display: show ? "block" : "none",
  };

  return (
    <div className="modal" style={modalStyles} onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={onClose}>&times;</span>
        {children}
      </div>
    </div>
  );
};

export default Modal;
