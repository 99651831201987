import React from "react";

import Icons from "./ProspectusIcons";
import PROSPECTUSIMG from "../../../assets/pros.png";

const Prospectus = () => {
  const CATEGORIES = [
    {
      icon: Icons.Drama,
      name: "Drama",
    },
    {
      icon: Icons.VisualArt,
      name: "Visual Art",
    },
    {
      icon: Icons.CreativeWriting,
      name: "Creative Writing",
    },
    {
      icon: Icons.ModelingCosplay,
      name: "Modeling & Cosplay",
    },
    {
      icon: Icons.Dance,
      name: "Dance",
    },
    {
      icon: Icons.Vocal,
      name: "Vocal",
    },
    {
      icon: Icons.Instrumental,
      name: "Instrumental",
    },
    {
      icon: Icons.Groups,
      name: "Small Groups",
    },
  ];
  return (
    <>
      <div className="pro-block">
        <div className="pro-block__content">
          <h2 className="pbc__title">Prospectus</h2>
          <div className="pbc__categories-block">
            <h4 className="pbc__categories--sub-title">
              The Eisteddfod offers the following categories:
            </h4>
            <div className="pbc__categories">
              <ul className="pbc__categories--list">
                {CATEGORIES.map((category) => (
                  <li className="pbc__category" key={category.name}>
                    <img
                      src={category.icon}
                      alt=""
                      className="pbc__category--icon"
                    />
                    <p className="pbc__category--name">{category.name}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="pbc__btn-block">
            <a href={require("../../../assets/Prospectus/PROSPECTUS PERSERVARIA EISTEDDFOD.pdf")} download>
              <button className="pbc__btn">Download</button>
            </a>
            <a href={require("../../../assets/Prospectus/SET PIECES BOOKLET PERSERVARIA EISTEDDFOD.pdf")} download>
              <button className="pbc__btn">Set Pieces</button>
            </a>
          </div>
        </div>
        <div className="pro-block__img-container">
          <img src={PROSPECTUSIMG} alt="" className="pro-block__img" />
        </div>
      </div>
    </>
  );
};

export default Prospectus;
