import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Navbar from "./components/layout/Navbar/Navbar";
import Home from "./components/layout/Home/Home";
import About from "./components/layout/About/About";
import Prospectus from "./components/layout/Prospectus/Prospectus";
import EnrollOption from "./components/layout/EnrollOption/EnrollOption";
import EntryForm from "./pages/Enroll/EntryForm";
import SpecialCat from "./pages/Enroll/SpecialCat";

import "./App.css";
import Footer from "./components/layout/Footer/Footer";
import Contact from "./components/layout/Home/Contact/Contact";
import Gallery from "./components/layout/Gallery/Gallery";

function App() {
  return (
    <Router>
      <Navbar />
      <div className="row">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/prospectus" element={<Prospectus />} />
          <Route path="/enrollOption" element={<EnrollOption />} />
          <Route path="/entryForm" element={<EntryForm />} />
          <Route path="/specialcat" element={<SpecialCat />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;