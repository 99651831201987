const CATEGORY_DATA = [
  {
    Drama: [
      "Set Poetry",
      "Own Choice Poetry",
      "Dramatized Poetry",
      "Limerick",
      "Monologue",
      "Mime",
      "TikTok Performance",
      "Prepared Reading",
      "Religious Reading",
      "Self-Written Work",
      "Advertisement",
      "Group Poetry",
    ],
  },
  {
    Art: [
      "Decoupage",
      "Scrapbooking",
      "Collage",
      "Vision board",
      "Digital Presentation",
      "3D Art Model(Physical/Digital)",
      "Cermaic Painting",
      "Mixed Media",
      "Drawing - Pencil",
      "Drawing - Charcoal",
      "Drawing - Crayons",
      "Painting - Water Based",
      "Painting - Oil Based",
      "Logo design",
      "Mosaic",
      "Photography",
      "Creative Art",
      "Open Category",
    ],
  },
  {
    "Creative Writing": [
      "Essay",
      "Short Story",
      "Self-Written Poem",
      "Public Speaking Speech",
      "Comic Strip",
      "Dialogue",
    ],
  },
  {
    "Model & Cosplay": [
      "Casual",
      "Formal",
      "Sport",
      "Style",
      "Costume/ Cosplay",
      "Photogenic",
      "TikTok Transition",
    ],
  },
  {
    "Vocal & Instrumental": [
      "Afrikaans",
      "Native Language",
      "Acoustic",
      "POP",
      "Foreign",
      "Country",
      "Jazz",
      "Gospel",
      "Movie Soundtrack",
      "Musical Theatre",
      "R&B / Hip Hop",
      "Opera",
      "A - Capellla",
      "Music Exam Work",
      "Sheet Music or Programs",
      "Self-Written or Composed",
      "Rock/ Alternative",
      "Open",
    ],
  },
  {
    Dance: [
      "Modern",
      "Jazz",
      "Disco",
      "Contemporary",
      "Hip Hop",
      "Lyrical",
      "Ballet",
      "Acrobatic",
      "Freestyle",
      "Tap",
      "TikTok Dances",
      "Open",
    ],
  },
];

export default CATEGORY_DATA;
