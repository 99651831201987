import LIANI from "../../../assets/liani.JPG";
import HESTA from "../../../assets/hesta.jpg"

export const TEAM_MEMBERS = [
  {
    name: "Liani de Jager",
    title: "Owner & Organizer",
    description:
      "Liani de Jager is a passionate drama teacher with an absolute love for children. She is also the owner of Lavender Drama Studio and the Creator of the Perservaria LSEN Eisteddfod. She graduated at the top of her class in 2019 with her Licentiate in Speech and Drama from the Speech and Drama College of South Africa. Liani was also the senior teacher and eventual manager of LALT Theatre House from 2016 - 2020 before starting Lavender Drama Studio.",
    acknowledgements: [
      "ATKV Redenaars Accredited Adjudicator & Speech Writer.",
      "National Arts Association Adjudicator.",
      "Speech and Drama Licentiate and Teaching Diploma - Cum Laude.",
      "The administrator of the NAA Performing Arts Camp 2018-2019.",
      "Trained in the various fields of Drama, Creative Writing, Dance, Vocal and Instrumental, and Modelling.",
      "ATKV Gauteng West Semi Final Adjudicator 2022.",
      "She appeared on television shows Drama Mamma's and Vergewe My.",
    ],
    image: LIANI,
  },
  {
    name: "Hesta Williams",
    title: "Co-Organizer & School Representative",
    description:
      "Mrs. Hesta Williams has always shown a deep interest in performing arts. As an author and content creator who has been diagnosed with ADHD and whose own children have special needs, she is an ambassador for equal treatment of and celebration of learners’ abilities regardless of their challenges.  She is a hardworking and innovative organizer and representative for the Perservaria Eisteddfod LSEN Schools around Gauteng. Having 30+ years of experience working with LSEN students, Mrs. Williams is passionate about invitational special needs education and including learners with special needs in the Performing arts without bias or prejudice. She has been passionate about public speaking since school and was a member of the Junior City Council of Pretoria.",
    acknowledgements: [
      "ATKV Redenaars Accredited Adjudicator.",
      "National Arts Association Adjudicator.",
      "Senior Phase Departmental Head at the School of Achievement.",
      "Experienced adjudicator in the sections of drama and creative writing.",
      "Certificate in Journalism.",
      "Editor of Student newspapers at school and university levels.",
    ],
    image: HESTA,
  },
];
