import React, { useState } from "react";
import emailjs from "emailjs-com";
import {
  EMAIL_SERVICE_ID,
  USER_ID,
  EMAIL_TEMPLATE_CONTACT_US,
} from "../../../../assets/email";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailServiceID = EMAIL_SERVICE_ID;
    const emailTemplateID = EMAIL_TEMPLATE_CONTACT_US;
    const userID = USER_ID;

    emailjs
      .send(
        emailServiceID,
        emailTemplateID,
        {
          name: name,
          email: email,
          message,
        },
        userID
      )
      .then(
        (response) => {
          alert("Email sent successfully:", response);
        },
        (error) => {
          alert("Email send failed:", error);
        }
      );

    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <>
      <div className="contact__container">
        <div className="contact__text">
          <h2>Have Any Questions?</h2>
          <p>
            Please let us know if you have any questions or concerns. Any
            feedback is welcome!
          </p>
        </div>
        <form className="contact__form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              className="contact-form_input contact-form_input-text"
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="Your Name"
            />
            <input
              className="contact-form_input contact-form_input-text"
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
            />
          </div>
          <div className="form-group_text">
            <textarea
              className="contact-form_input contact-form_input-message"
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows="4"
              required
              placeholder="Your message"
            ></textarea>
          </div>
          <button className="contact__btn" type="submit">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default Contact;
