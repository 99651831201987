import React from 'react'

import opportunity from '../../../../assets/Opportunity.png'
import inclusiveness from '../../../../assets/Inclusiveness.png'
import achievement from '../../../../assets/Achievement.png'

const Mission = () => {
  return (
    <>
        <div className="mission__container">
            <h2 className="mission__title">Our Mission</h2>
            <div className="missions">
                <div className="mission">
                    <img src={opportunity} alt="" className="mission__icon" />
                    <h3 className="mission__sub-title">Opportunity</h3>
                    <p className="mission-para">We strive to provide performance opportunities for all special needs learners.</p>
                </div>
                <div className="mission">
                    <img src={inclusiveness} alt="" className="mission__icon" />
                    <h3 className="mission__sub-title">Inclusiveness</h3>
                    <p className="mission-para">We strive to accommodate all physical, emotional, and cognitive challenges learners may have, in a safe, invitational environment.</p>
                </div>
                <div className="mission">
                    <img src={achievement} alt="" className="mission__icon" />
                    <h3 className="mission__sub-title">Achievement</h3>
                    <p className="mission__para">All learners will receive accreditation for what they put on stage or on canvas.</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default Mission