import React from "react";
import { Link } from "react-router-dom";

import SOIMG from "../../../../assets/Different.png";

const StandOutBlock = () => {
  return (
    <>
      <div className="so__block">
        <div className="so__block--left">
          <div className="so__block--container">
            <h2 className="so__block--title">
              What makes us different from any other Eisteddfod?
            </h2>
            <p className="so__block--para">
              We aim to provide the ideal Eisteddfod to LSEN learners. In the
              past, these learners had to face the challenges of competing in
              the mainstream Eisteddfods and were often overwhelmed by
              dissapointment due to the extremely high standard in which
              students were adjudicated on the talent that they showcased.
            </p>
            <Link to="/about">
              <button className="so__block--btn">About</button>
            </Link>
          </div>
        </div>
        <div className="so__block--right">
          <img src={SOIMG} alt="" className="so__block--img" />
        </div>
      </div>
    </>
  );
};

export default StandOutBlock;
