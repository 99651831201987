import React, { useEffect, useState } from "react";

import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import image1 from "./Fotos/2023/image1.jpg";
import image2 from "./Fotos/2023/image2.jpg";
import image3 from "./Fotos/2023/image3.jpg";
import image4 from "./Fotos/2023/image4.jpg";
import image5 from "./Fotos/2023/image5.jpg";
import image6 from "./Fotos/2023/image6.jpg";
import image7 from "./Fotos/2023/image7.jpg";
import image8 from "./Fotos/2023/image8.jpg";
import image9 from "./Fotos/2023/image9.jpg";
import image10 from "./Fotos/2023/image10.jpg";
import image11 from "./Fotos/2023/image11.jpg";
import image12 from "./Fotos/2023/image12.jpg";
import image13 from "./Fotos/2023/image13.jpg";
import image14 from "./Fotos/2023/image14.jpg";
import image15 from "./Fotos/2023/image15.jpg";
import image16 from "./Fotos/2023/image16.jpg";
import image17 from "./Fotos/2023/image17.jpg";
import image18 from "./Fotos/2023/image18.jpg";
import image19 from "./Fotos/2023/image19.jpg";
import image20 from "./Fotos/2023/image20.jpg";
import image21 from "./Fotos/2023/image21.jpg";
import image22 from "./Fotos/2023/image22.jpg";
import image23 from "./Fotos/2023/image23.jpg";
import image24 from "./Fotos/2023/image24.jpg";
import image25 from "./Fotos/2023/image25.jpg";
import image26 from "./Fotos/2023/image26.jpg";
import image27 from "./Fotos/2023/image27.jpg";

const Gallery = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoaded(true);
    }, 5000); // Adjust the timeout duration as needed

    return () => clearTimeout(timeoutId);
  }, []);

  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    image26,
    image27,
  ];

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  const itemData = images.map((img, index) => ({
    img,
    title: `Image ${index + 1}`,
    cols: index % 3 === 0 ? 2 : 1, // Adjust the number of columns based on your preference
    rows: index % 3 === 0 ? 2 : 1, // Adjust the number of rows based on your preference
  }));

  return (
    <>
      {loaded ? (
        <div className="carousel-wrapper">
          <Carousel autoPlay infiniteLoop interval={2500}>
            {images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Image ${index + 1}`} loading="lazy" />
              </div>
            ))}
          </Carousel>
        </div>
      ) : (
        <h1 className="images-laoding">Images Loading...</h1>
      )}

      {loaded ? <h1 className="gallery-title">Our Showcase Gallery</h1> : null}

      {loaded ? (
        <div className="gallery-wrapper">
          <ImageList
            sx={{ width: 500, height: 450 }}
            variant="quilted"
            cols={4}
            rowHeight={121}
          >
            {itemData.map((item) => (
              <ImageListItem
                key={item.img}
                cols={item.cols || 1}
                rows={item.rows || 1}
              >
                <img
                  {...srcset(item.img, 121, item.rows, item.cols)}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      ) : null}
    </>
  );
};

export default Gallery;
