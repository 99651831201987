import React from "react";
import { Link } from "react-router-dom";

import ABOUTIMG from "../../../assets/About.png";

import { TEAM_MEMBERS } from "./Team";

const About = () => {
  const renderAcknowledgements = (acknowledgements) => {
    return acknowledgements.map((ack, index) => (
      <li className="team-member__acknowledgement" key={index}>
        {ack}
      </li>
    ));
  };

  return (
    <>
      <div className="about__intro--block">
        <figure className="about__into--img-wrapper">
          <img src={ABOUTIMG} alt="" className="about__intro--img" />
        </figure>
        <div className="about__intro--container">
          <h2 className="about__intro--title">About Us</h2>
          <p className="about__intro--para">
            In 2020, the Perservaria Eisteddfod was merely an idea of two
            teachers passionate about special needs learners and performing
            arts. That idea soon became a reality when the Eisteddfod was born
            in 2021 with a complete online competition.
          </p>
          <br />
          <p className="about__intro--para">
            Currently, the Perservaria Eisteddfod is one of the biggest LSEN
            Eisteddfods in South Africa. In-person performances are currently
            done in Gauteng, with an online platform to accommodate nationwide
            participation.
          </p>
          <br />
          <p className="about__intro--para">
            As an Eisteddfod that is expanding exponentially on a yearly basis,
            we are very proud to be leaders in innovation when it comes to the
            recognition of new art forms and expression and will be looking into
            educator training and coaching for LSEN schools in the near future.
          </p>
          <br />
          <p className="about__intro--para">
            The Perservaria Eisteddfod offers participation in the categories of
            Drama, Visual Arts, Creative Writing, Modelling & Cosplay, Dance,
            Vocal & Instrumental, and Small Group Performances
          </p>
          <div className="btn__container">
            <Link to="/enrollOption">
              <button className="about__intro--btn">Enter Now</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="about__team--block">
        <div className="about__team--title-block">
          <h3 className="about__team--title">Meet Our Experienced Team</h3>
        </div>
        <ul className="team__members">
          {TEAM_MEMBERS.map((member) => (
            <li className="team__member" key={member.name}>
              <figure className="team-member__img--container">
                <img src={member.image} alt="" className="team-member__img" />
              </figure>
              <div className="team__member--info">
                <h4 className="team-member__name">{member.name}</h4>
                <h6 className="team-member__title">{member.title}</h6>
                <p className="team-member__description">{member.description}</p>
                <br />
                <ul className="team-member__acknowledgements">
                  {renderAcknowledgements(member.acknowledgements)}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default About;
