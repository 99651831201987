import Drama from '../../../assets/Prospectus/Drama.png'
import VisualArt from '../../../assets/Prospectus/art.png'
import CreativeWriting from '../../../assets/Prospectus/creativewriting.png'
import ModelingCosplay from '../../../assets/Prospectus/modeling.png'
import Dance from '../../../assets/Prospectus/dance.png'
import Vocal from '../../../assets/Prospectus/vocal.png'
import Instrumental from '../../../assets/Prospectus/instrumental.png'
import Groups from '../../../assets/Prospectus/group.png'

const Icons = {Drama, VisualArt, ModelingCosplay, Dance, Vocal, Instrumental, Groups, CreativeWriting}

export default Icons