import React, { useState } from "react";
import { PaystackButton } from "react-paystack";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";
import specialData from "../Enroll/specialCatData/specialData";
import Modal from "./popUp/Modal";
import {
  EMAIL_SERVICE_ID,
  USER_ID,
  EMAIL_TEMPLATE_SPECIAL_CAT_FORM,
  EMAIL_TEMPLATE_RECEIPT_SPECIAL,
} from "../../../src/assets/email";

const SpecialCat = () => {
  const [formData, setFormData] = useState({
    groupName: "",
    email: "",
    age: "",
    school: "",
    phoneNum: "",
    location: "Select Location",
    category: "",
    language: "",
    amount: 35000,
  });

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const emailServiceID = EMAIL_SERVICE_ID;
  const emailTemplateSpecialCat = EMAIL_TEMPLATE_SPECIAL_CAT_FORM;
  const emailTemplateReceiptSpecial = EMAIL_TEMPLATE_RECEIPT_SPECIAL;
  const userID = USER_ID;

  const handleGroupChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      groupName: event.target.value,
    }));
  };

  const handleEmailChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      email: event.target.value,
    }));
  };

  const handleSchoolChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      school: event.target.value,
    }));
  };

  const handleAgeChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      age: event.target.value,
    }));
  };

  const handlePhoneNumChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      phoneNum: event.target.value,
    }));
  };

  const handleLocationChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      location: event.target.value,
    }));
  };

  const handleLanguageChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      language: event.target.value,
    }));
  };

  const handleCategoryChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      category: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation checks
    const errors = {};

    if (formData.groupName.length < 3) {
      errors.name = "Name must be longer than 3 characters.";
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (formData.school.length < 3) {
      errors.school = "School name must be longer than 3 characters.";
    }

    if (Object.keys(errors).length > 0) {
      alert("Validation errors:\n" + Object.values(errors).join("\n"));
    } else {
      setFormData((prevData) => ({
        ...prevData,
      }));
    }
  };

  const handleSuccess = ({ reference }) => {
    setModalContent(
      `Your purchase was successful! Transaction reference: ${reference}`
    );
    setShowModal(true);
    resetForm();
    sendEmailSpecialCat(formData.email, formData.groupName, reference);
    sendEmailReceiptSpecial(formData.email, formData.groupName, reference);
  };

  const handleClose = () => {
    setModalContent("Wait! Are you sure you don't want to enroll?");
    setShowModal(true);
  };

  const resetForm = () => {
    setFormData({
      groupName: "",
      email: "",
      age: "",
      school: "",
      phoneNum: "",
      location: "Select Location",
      item: "",
      language: "",
      amount: 35000,
    });
  };

  const sendEmailSpecialCat = async (toEmail, toName, reference) => {
    const templateParams = {
      to_email: toEmail,
      to_name: toName,
      from_email: formData.email,
      form_groupName: formData.groupName,
      form_email: formData.email,
      form_age: formData.age,
      form_school: formData.school,
      form_phoneNum: formData.phoneNum,
      form_location: formData.location,
      form_language: formData.language,
      form_item: formData.item,
      form_amount: formData.amount / 100,
      reference: reference,
    };

    await emailjs.send(
      emailServiceID,
      emailTemplateSpecialCat,
      templateParams,
      userID
    );
  };

  const sendEmailReceiptSpecial = async (toEmail, toName, reference) => {
    const templateParams = {
      to_email: toEmail,
      to_name: toName,
      from_email: formData.email,
      form_groupName: formData.groupName,
      form_email: formData.email,
      form_age: formData.age,
      form_school: formData.school,
      form_phoneNum: formData.phoneNum,
      form_location: formData.location,
      form_language: formData.language,
      form_item: formData.category,
      form_amount: formData.amount / 100,
      reference: reference,
    };

    await emailjs.send(
      emailServiceID,
      emailTemplateReceiptSpecial,
      templateParams,
      userID
    );
  };

  const email = formData.email;
  const name = formData.groupName;
  const amount = formData.amount;
  const phone = formData.phoneNum;
  const currency = "ZAR";
  const publicKey = "pk_live_7f669c49c720f76bc90db22e3b46e31a131ff7a3";

  const componentProps = {
    email,
    amount,
    currency,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Submit",
    onSuccess: ({ reference }) => {
      handleSuccess({ reference });
      resetForm();
    },
    onClose: handleClose,
  };

  return (
    <>
      <form className="enrollment__form" onSubmit={handleSubmit}>
        <div className="form__enrollment--wrapper">
          <h3 className="form__enrollment--title">
            Enrollment - Special Categories
          </h3>
          <div className="enrollment__input--wrapper">
            <input
              type="text"
              name="name"
              value={formData.groupName}
              placeholder="Group Name"
              onChange={handleGroupChange}
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder="Email"
              onChange={handleEmailChange}
              required
            />
            <input
              type="text"
              name="school"
              placeholder="School"
              value={formData.school}
              onChange={handleSchoolChange}
              required
            />
            <select
              name="age"
              value={formData.age}
              onChange={handleAgeChange}
              required
            >
              <option value="" disabled>
                Select Age
              </option>
              {[...Array(17)].map((_, index) => (
                <option key={index} value={index + 5}>
                  {index + 5} years of age
                </option>
              ))}
            </select>
            <input
              type="text"
              name="phoneNum"
              placeholder="Phone"
              value={formData.phoneNum}
              onChange={handlePhoneNumChange}
              required
            />
            <select
              name="location"
              value={formData.location}
              onChange={handleLocationChange}
              required
            >
              <option value="Select Location" disabled>
                Select Venue
              </option>
              <option value="In Person">In Person</option>
              <option value="Online">Online</option>
            </select>
          </div>
        </div>
        <div className="form__entry--wrapper">
          <h3 className="form__entry--title">Entry Form</h3>
          <div className="entry__input--wrapper">
            <div>
              <div className="select__wrapper">
                <label htmlFor="language">Select Language:</label>
                <div>
                  <select
                    id="language"
                    value={formData.language}
                    onChange={handleLanguageChange}
                    required
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="English">English</option>
                    <option value="Afrikaans">Afrikaans</option>
                    <option value="Both">Both</option>
                  </select>
                </div>
              </div>
              <div className="select__wrapper">
                <label htmlFor="category">Select Category:</label>
                <div>
                  <select
                    id="category"
                    value={formData.category}
                    onChange={handleCategoryChange}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {specialData.map((categoryName, index) => (
                      <option key={index} value={categoryName}>
                        {categoryName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div>
            <PaystackButton className="paystack-button" {...componentProps} />
          </div>

          <Modal show={showModal} onClose={() => setShowModal(false)}>
            <p>{modalContent}</p>
          </Modal>
          <div className="exit__wrapper">
            <Link to="/" className="exit__btn">
              Home
            </Link>
          </div>
        </div>
      </form>
    </>
  );
};

export default SpecialCat;
